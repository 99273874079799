import styled from "styled-components";
import { CardContainer } from "../Card/Card.styles";

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  button {
    min-width: 37px;
    border-radius: 50px;
  }
`;

export const QRCodeContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

  svg {
    box-shadow: 5px 5px 20px -5px #00000091;
  }
`;

export const TableContainer = styled.div<{ $loading?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  padding-top: 1rem;
  transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  opacity: ${({ $loading }) => ($loading ? 0.2 : 1)};
`;

export const AnswersContainer = styled.div<{ $show: boolean }>`
  display: flex;
  justify-content: center;
  gap: 1rem;
  transition: 500ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  translate: 0 ${({ $show }) => ($show ? 0 : 500)}%;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const AnswerCards = styled.div<{
  $count: number;
  $visible?: boolean;
  $voteStarted?: boolean;
  $voteFinished?: boolean;
  $won?: boolean;
}>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  opacity: ${({ $visible }) => ($visible ? 1 : -1)};
  translate: ${({ $visible }) => ($visible ? 0 : 500)}%
    ${({ $visible }) => ($visible ? 0 : -100)}%;
  max-width: ${({ $visible }) => ($visible ? 300 : 0)}px;
  -webkit-tap-highlight-color: transparent;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  gap: 0.5rem;
  height: fit-content;

  min-height: calc(
    ${({ $voteStarted, $voteFinished }) =>
        ($voteFinished && "52px") || ($voteStarted && "44px") || "0"} +
      var(--card-size) / 2 + var(--card-size) / 2 * ${({ $count }) => $count}
  );

  button {
    margin: 0 auto;
    width: fit-content;
  }

  & > div {
    transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
    rotate: ${({ $visible }) => ($visible ? 360 : 0)}deg;
    -webkit-tap-highlight-color: transparent;
  }

  ${CardContainer} {
    animation: ${({ $won }) => $won && "BorderSpin 2s infinite linear"};
  }

  @keyframes BorderSpin {
    0% {
      border-color: ${({ theme }) => theme.palette.success.dark};
    }
    33% {
      border-color: ${({ theme }) => theme.palette.success.main};
    }
    66% {
      border-color: ${({ theme }) => theme.palette.success.light};
    }
    100% {
      border-color: ${({ theme }) => theme.palette.success.dark};
    }
  }

  ${({ $won, theme }) =>
    $won &&
    `
      .MuiTypography-caption.MuiTypography-caption {
        color: ${theme.palette.success.light};
        font-weight: 600;
        font-size: 14px;
      }
    `}
`;

export const AnswerWrapper = styled.div<{ $open?: boolean }>`
  height: ${({ $open }) =>
    $open ? "var(--card-size)" : "calc(var(--card-size) / 2)"};
  overflow: visible;
  transition: 200ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
  &:not(:first-child) {
    ${CardContainer} {
      box-shadow: ${({ $open }) => ($open ? "none" : "0px -2px 5px -2px gray")};
    }
  }
`;

export const QuestionContainer = styled.div<{ $loading?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  position: relative;

  button {
    position: absolute;
    bottom: 0;
    left: 50%;
    translate: -50% -50%;
  }

  ${CardContainer} {
    cursor: default;
    transition: 300ms cubic-bezier(0.46, -0.39, 0.57, 1.32);
    rotate: ${({ $loading }) => ($loading ? "-180deg" : "0deg")};
    translate: 0 ${({ $loading }) => ($loading ? "-200%" : "0%")};
  }
`;
